<template>
  <div class="content">
    <div class="search-box">
      <el-form :inline="true" :model="searchFrom" ref="searchFrom" size="small">
        <el-form-item :label="$t('order.Refund.396382-0')" label-width="100px" prop="serialNumber">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.serialNumber"
            :placeholder="$t('order.Refund.396382-1')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('order.Refund.396382-2')" label-width="100px" prop="goodsName">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.goodsName"
            :placeholder="$t('order.Refund.396382-3')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('order.Refund.396382-4')" label-width="100px" prop="refundState">
          <!-- <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.refundState"
            :placeholder="$t('order.Refund.396382-3')"
          ></el-input> -->
          <el-select
            clearable
            v-model="searchFrom.refundState"
            :placeholder="$t('order.Refund.396382-5')"
            style="width: 240px"
          >
            <el-option
              v-for="item in $dict.refundState"
              :key="item.code"
              :label="item.text"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('order.Refund.396382-6')" label-width="100px" prop="phone">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.phone"
            :placeholder="$t('order.Refund.396382-7')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('order.Refund.396382-4')" label-width="100px" prop="phone">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.refundState"
            :placeholder="$t('order.Refund.396382-7')"
          ></el-input>
        </el-form-item> -->
        <el-form-item label-width="100px" :label="$t('order.Refund.396382-8')" prop="wlStatus">
          <el-select
            style="width: 240px"
            clearable
            v-model="searchFrom.wlStatus"
            :placeholder="$t('order.Refund.396382-9')"
          >
            <el-option :label="$t('order.Refund.396382-10')" value="1"></el-option>
            <el-option :label="$t('order.Refund.396382-11')" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.Refund.396382-12')" prop="wlStatus">
          <el-select
            style="width: 240px"
            clearable
            v-model="searchFrom.isPubGoods"
            :placeholder="$t('order.Refund.396382-13')"
          >
            <el-option :label="$t('order.Refund.396382-14')" value="0"></el-option>
            <el-option :label="$t('order.Refund.396382-15')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="margin-left: 100px"
            type="primary"
            icon="el-icon-search"
            @click="search"
            >{{ $t('order.Refund.396382-16') }}</el-button
          >
          <el-button @click="resetForm">{{ $t('order.Refund.396382-17') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- -----------------------表格区域 --------------------------------->
    <div class="content-table">
      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        border
      >
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="serialNumber" :label="$t('order.Refund.396382-0')" width="auto">
        </el-table-column>
        <el-table-column prop="userId" :label="$t('order.Refund.396382-18')" width="auto">
        </el-table-column>
        <!-- <el-table-column prop="productName" :label="$t('order.Refund.396382-2')"> </el-table-column>
        <el-table-column prop="quantity" label="商品数量" width="auto">
        </el-table-column> -->
        <el-table-column prop="agreementRefund" :label="$t('order.Refund.396382-19')" width="auto">
        </el-table-column>
        <el-table-column prop="phone" :label="$t('order.Refund.396382-6')" width="auto">
        </el-table-column>
        <el-table-column prop="reason" :label="$t('order.Refund.396382-20')" width="auto">
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('order.Refund.396382-21')" width="auto">
        </el-table-column>

        <el-table-column :label="$t('order.Refund.396382-8')" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.wlStatus == 0">{{ $t('order.Refund.396382-11') }}</span>
            <span v-else-if="scope.row.wlStatus == 1">{{ $t('order.Refund.396382-10') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('order.Refund.396382-12')" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.isPubGoods == 0">{{ $t('order.Refund.396382-14') }}</span>
            <span v-else-if="scope.row.isPubGoods == 1">{{ $t('order.Refund.396382-15') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessCheckState" :label="$t('order.Refund.396382-4')" width="auto">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.businessCheckState === 0 &&
                  scope.row.serviceCheckState === 0
              "
              >{{ $t('order.Refund.396382-22') }}
            </span>
            <span
              v-else-if="
                scope.row.businessCheckState === 1 ||
                  scope.row.serviceCheckState === 1
              "
              >{{ $t('order.Refund.396382-23') }}</span
            >
            <span
              v-else-if="
                scope.row.businessCheckState === 2 ||
                  scope.row.serviceCheckState === 2
              "
              >{{ $t('order.Refund.396382-24') }}</span
            >
            <!-- <span>{{
              $dict.refundState | dictFormat(scope.row.businessCheckState)
            }}</span> -->
          </template>
        </el-table-column>
        <el-table-column :label="$t('order.Refund.396382-25')" width="auto">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                toOrderDetails(
                  scope.row.id,
                  scope.row.businessCheckState,
                  scope.row
                )
              "
              >{{ $t('order.Refund.396382-26') }}</el-button
            >
            <el-button
              v-if="
                scope.row.businessCheckState === 0 &&
                  scope.row.serviceCheckState === 0
              "
              type="text"
              size="small"
              @click="audit(scope.row)"
              >{{ $t('order.Refund.396382-27') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :title="$t('order.Refund.396382-28')" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form
          ref="auditForm"
          :model="form"
          :rules="rules"
          :before-close="auditDialogClose"
        >
          <el-form-item :label="$t('order.Refund.396382-29')" prop="idea" label-width="120px">
            <el-input
              type="text"
              :placeholder="$t('order.Refund.396382-30')"
              v-model="form.idea"
            />
          </el-form-item>
          <el-form-item :label="$t('order.Refund.396382-31')" prop="status" label-width="120px">
            <el-select v-model="form.status" :placeholder="$t('order.Refund.396382-32')">
              <el-option :label="$t('order.Refund.396382-23')" value="1"> </el-option>
              <el-option :label="$t('order.Refund.396382-24')" value="2"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="auditDialogClose">{{ $t('order.Refund.396382-33') }}</el-button>
        <el-button type="primary" @click="auditSubmit">{{ $t('order.Refund.396382-34') }}</el-button>
      </span>
    </el-dialog>
    <!-- 侧边栏查看详情 -->
    <el-drawer :title="$t('order.Refund.396382-35')" size="60%" :visible.sync="detailsDrawerVisible">
      <Details
        :orderData="orderDetailData"
        :isRefund="true"
        :refundType="refundType"
      ></Details>
    </el-drawer>
  </div>
</template>
<script>
import Details from "@/views/manage/order/Details.vue";
export default {
  data() {
    return {
      refundType: null,
      detailsDrawerVisible: null,
      orderDetailData: null,
      searchFrom: {
        phone: "",
        serialNumber: "",
        goodsName: "",
        refundState: null,
        wlStatus: null,
        isPubGoods: null,
      },
      // 审核意见
      form: {
        idea: "",
        status: null,
        quantity: null,
      },
      dialogVisible: false, //审核意见
      // 校验规则
      rules: {
        idea: [
          { required: true, message: this.$t('order.Refund.396382-30'), trigger: "blur" },
        ],
        status: [
          { required: true, message: this.$t('order.Refund.396382-36'), trigger: "change" },
        ],
      },
      // 表格数据
      tableData: [],
      loading: false,
      // 条数
      pageSize: 10,
      // 当前页
      pageNum: 1,
      // 总条数
      total: 0,
      currentId: null,
    };
  },
  components: {
    Details,
  },
  created() {
    this.getOrderList();
  },
  methods: {
    toOrderDetails(id, refundType, row) {
      // 当前退款状态0退款成功，1退款失败，2退款中
      // row.businessCheckState === 1
      //  row.serviceCheckState === 1
      // const state = null;
      if (row.shippingState === 7) {
        this.refundType = 0; //退款成功
      } else {
        if (row.businessCheckState === 2 || row.serviceCheckState === 2) {
          this.refundType = 1; //退款失败
        } else {
          this.refundType = 2; //退款中
        }
      }

      // this.refundType = state;

      const isSharedGoods = row.isPubGoods;
      // console.log(id);
      // 查看 详情
      this.$api.order
        .orderDetails({ id: id, isSharedGoods: isSharedGoods })
        .then((res) => {
          const { data, success, message } = res;
          // data
          if (!success) {
            this.$message.error(message);
            return;
          }
          this.orderDetailData = data;
          this.detailsDrawerVisible = true;
        });
    },
    resetForm() {
      this.searchFrom = {};
      // this.$refs["searchFrom"].resetFields();
      this.getOrderList();
    },
    // 搜索
    search() {
      this.getOrderList();
    },
    // 弹窗显示 审核
    audit(row) {
      let id = row.id;
      this.currentId = row.id;
      this.form.quantity = row.quantitys;
      this.dialogVisible = true;
    },
    // 审核提交
    auditSubmit() {
      this.$refs["auditForm"].validate((valid) => {
        if (valid) {
          let resData = {
            id: this.currentId,
            result: this.form.status,
            msg: this.form.idea,
            quantity: this.form.quantity,
          };
          this.$api.order.auditRefundOrder(resData).then((res) => {
            const { data, success, message } = res;
            this.auditDialogClose();
            if (!success) {
              this.$message.error(message);
            } else {
              this.getOrderList();
            }
          });
        }
      });
    },
    auditDialogClose() {
      this.$refs["auditForm"].resetFields();
      // 弹窗关闭
      this.dialogVisible = false;
    },
    getOrderList() {
      let resData = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        refund: 1,
        ...this.searchFrom,
      };
      this.loading = true;
      this.$api.order.shopOrderList(resData).then((res) => {
        this.loading = false;
        if (!res.success) {
          this.tableData = [];
          this.total = 0;
          return;
        }
        this.tableData = res.data.list;
        this.total = parseInt(res.data.total);
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .pagination-box {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
